import AdvancedSearch from '@/components/AdvancedSearch/AdvancedSearch.vue';
import BatchMapping from '@/components/WorkingDashboard/BatchMapping.vue';
import ConceptHierarchy from '@/components/ConceptHierarchy/ConceptHierarchy.vue';
import Home from '@/components/Home.vue';
import Router from 'vue-router';
import SchemaManagement from '@/components/SchemaManagement/SchemaManagement.vue';
import TermCreate from '@/components/TermManagement/TermCreate.vue';
import TermEdit from '@/components/TermManagement/TermEdit.vue';
import TermView from '@/components/TermManagement/TermView.vue';
import Vue from 'vue';
import WorkingDashboard from '@/components/WorkingDashboard/WorkingDashboard.vue';
import WorkingDashboardStatus from '@/components/WorkingDashboard/WorkingDashboardStatus.vue';
import CustomQuery from '@/components/CustomQuery/CustomQuery.vue';

import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue, { LoginCallback } from '@okta/okta-vue';

// https://devforum.okta.com/t/okta-auth-js-autorenew-process/12640/6
const oidcConfig = {
  clientId: process.env.VUE_APP_OKTA_ID,
  issuer: 'https://morningstar.okta.com',
  redirectUri: `${process.env.VUE_APP_WEB_URL}/login/callback`,
  scopes: ['offline_access', 'openid', 'profile', 'email', 'groups'],
  pkce: false,
  tokenManager: {
    autoRenew: true,
    storage: localStorage,
  },
};

const oktaAuth = new OktaAuth(oidcConfig);

Vue.use(Router);
const requiresAuth = process.env.VUE_APP_REQUIRES_AUTH !== 'false';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: `${process.env.VUE_APP_TITLE} - Morningstar Knowledge System`,
        requiresAuth: requiresAuth,
      },
    },
    {
      path: '/login/callback',
      component: LoginCallback,
    },
    {
      path: '/custom-query',
      name: 'CustomQuery',
      component: CustomQuery,
      meta: { title: 'Custom Query', requiresAuth: requiresAuth },
    },
    {
      path: '/schema',
      name: 'SchemaManagement',
      component: SchemaManagement,
      meta: { requiresAuth: requiresAuth },
    },
    {
      path: '/schema/create',
      name: 'ClassCreate',
      component: SchemaManagement,
      meta: { requiresAuth: requiresAuth },
    },
    {
      path: '/schema/edit/:className',
      name: 'ClassEdit',
      component: SchemaManagement,
      meta: { requiresAuth: requiresAuth },
    },
    {
      path: '/schema/view/:className',
      name: 'ClassView',
      component: SchemaManagement,
      meta: { requiresAuth: requiresAuth },
    },
    {
      path: '/concept-hierarchy/:conceptId?',
      name: 'ConceptHierarchy',
      component: ConceptHierarchy,
      meta: { title: 'Concept Hierarchy', requiresAuth: requiresAuth },
    },
    {
      path: '/working-dashboard',
      name: 'WorkingDashboardStatus',
      component: WorkingDashboardStatus,
      meta: { title: 'Working Dashboard', requiresAuth: requiresAuth },
    },
    {
      path: '/working-dashboard/:className',
      name: 'WorkingDashboard',
      component: WorkingDashboard,
      props: route => ({ locale: route.query.locale }),
      meta: { requiresAuth: requiresAuth },
    },
    {
      path: '/batch-mapping',
      name: 'BatchMapping',
      component: BatchMapping,
      meta: { requiresAuth: requiresAuth, title: 'Batch Mapping' },
    },
    {
      path: '/term/create',
      name: 'TermCreate',
      component: TermCreate,
      meta: { requiresAuth: requiresAuth, title: 'Create A New Term' },
    },
    {
      path: '/term/edit/:entryId',
      name: 'TermEdit',
      component: TermEdit,
      meta: { requiresAuth: requiresAuth, title: 'Edit Term' },
    },
    {
      path: '/term/view/:entryId',
      name: 'TermView',
      component: TermView,
      meta: { title: 'View Term', requiresAuth: requiresAuth },
    },
    {
      path: '/search',
      name: 'AdvancedSearch',
      component: AdvancedSearch,
      meta: { title: 'Advanced Search', requiresAuth: requiresAuth },
    },
  ],
});

Vue.use(OktaVue, { oktaAuth });

router.afterEach(to => {
  Vue.nextTick(() => {
    if (to.meta.hasOwnProperty('title')) {
      document.title = `${to.meta.title} | ${process.env.VUE_APP_TITLE}`;
    }
  });
});

export default router;
